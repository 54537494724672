@media(max-width: 320px)
    .wrapper
        position: relative
        margin: 0 auto
        display: flex
        align-items: center
        justify-content: center
        &>a
            text-decoration: none
            color: black
        &>.btn
            width: 90%
            display: none
        &:hover>.btn
            display: block
            position: absolute
            top: 53%
            // left: 0px
        &>.product_container
            display: flex
            flex-direction: column
            gap: 10px
            width: 279px
            height: 400px
            &>.product_img
                padding-bottom: 10px
                width: 100%
                height: 300px
                &>img
                    object-fit: contain
                    width: 100%
                    height: 100%
            &>.product_price
                padding-left: 10px
                display: flex
                flex-direction: row
                align-items: center
                gap: 30px
                &>.clear_price
                    font-weight: 600
                    font-size: 22px
                &>.discount_price
                    font-weight: 600
                    font-size: 22px
                &>.price
                    font-size: 12px
                    font-weight: 500
                    text-decoration: line-through
                    color: #8B8B8B
                &>.discount
                    font-size: 12px
                    font-weight: 600
                    color: #FF32A1
            &>p
                padding-left: 10px
                font-weight: 500
                font-size: 12px

@media(min-width: 321px)
    .wrapper
        // width: 80%
        position: relative
        margin: 0 auto
        display: flex
        align-items: center
        justify-content: center
        &>a
            text-decoration: none
            color: black
        &>.btn
            width: 90%
            display: none
        &:hover>.btn
            display: block
            position: absolute
            top: 53%
            // left: 55px
        &>.product_container
            display: flex
            flex-direction: column
            gap: 10px
            width: 100%
            height: 450px
            &>.product_img
                padding-bottom: 10px
                // width: 80%
                height: 360px
                &>img
                    object-fit: cover
                    width: 100%
                    height: 100%
            &>.product_price
                padding-left: 10px
                display: flex
                flex-direction: row
                align-items: center
                gap: 30px
                &>.clear_price
                    font-weight: 600
                    font-size: 22px
                &>.discount_price
                    font-weight: 600
                    font-size: 22px
                &>.price
                    font-size: 12px
                    font-weight: 500
                    text-decoration: line-through
                    color: #8B8B8B
                &>.discount
                    font-size: 12px
                    font-weight: 600
                    color: #FF32A1
            &>p
                padding-left: 10px
                font-weight: 500
                font-size: 12px


@media(min-width: 576px)
    .wrapper
        position: relative
        margin: 0 auto
        &>a
            text-decoration: none
            color: black
        &>.btn
            display: none
        &:hover>.btn
            display: block
            position: absolute
            top: 53%
            left: 10px
        &>.product_container
            display: flex
            flex-direction: column
            gap: 10px
            width: 300px
            height: 400px
            &>.product_img
                padding-bottom: 10px
                width: 300px
                height: 300px
                &>img
                    object-fit: contain
                    width: 100%
                    height: 100%
            &>.product_price
                padding-left: 10px
                display: flex
                flex-direction: row
                align-items: center
                gap: 30px
                &>.clear_price
                    font-weight: 600
                    font-size: 22px
                &>.discount_price
                    font-weight: 600
                    font-size: 22px
                &>.price
                    font-size: 12px
                    font-weight: 500
                    text-decoration: line-through
                    color: #8B8B8B
                &>.discount
                    font-size: 12px
                    font-weight: 600
                    color: #FF32A1
            &>p
                padding-left: 10px
                font-weight: 500
                font-size: 10px

@media (min-width: 768px)
    .wrapper
        &>.product_container
            &>.product_price
                gap: 20px
                &>.clear_price
                    font-size: 23px
                &>.discount_price
                    font-size: 23px
                &>.price
                    font-size: 13px
                &>.discount
                    font-size: 13px
            &>p
                font-size: 13px

@media (min-width: 992px)
    .product_container
        &>.product_price
            gap: 20px
            &>.clear_price
                font-size: 25px
            &>.discount_price
                font-size: 25px
            &>.price
                font-size: 15px
            &>.discount
                font-size: 15px
        &>p
            font-size: 15px

@media (min-width: 1440px)
    .product_container
        &>.product_price
            gap: 30px
            padding-left: 15px
            &>.clear_price
                font-size: 30px
            &>.discount_price
                font-size: 30px
            &>.price
                font-size: 20px
            &>.discount
                font-size: 20px
        &>p
            font-size: 16px
            color: #3A3A3A
            padding-left: 15px
