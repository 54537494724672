@media(max-width: 320px)
    .wrapper
        padding: 40px 20px
        height: 495px
        display: flex
        flex-direction: column
        font-weight: 600
        gap: 20px
        &>.block_subheader
            margin-bottom: 20px
            font-weight: 700
            font-size: 25px
            display: flex
            flex-direction: row
            gap: 15px
            // align-items: center
            &>a
                text-decoration: none
                display: flex
                align-items: center
                &>button
                    cursor: pointer
                    width: 70px
                    height: 13px
                    font-size: 8px
                    color: #8B8B8B
                    border: 2px solid #8B8B8B
                    font-weight: 600
                    background-color: transparent
                    &:hover
                        color: black
                        border: 2px solid black
        &>.slider
            // padding: 0 82px
            height: 430px
            width: 100%
            margin: 0 auto
            position: relative
            overflow-x: hidden
            &>.trigger
                position: absolute
                font-size: 50px
                top: 35%
                cursor: pointer
                z-index: 100
                opacity: 0
                transition: opacity 0.5s ease
                &:hover
                    opacity: 1
            &>.left
                display: none
            &>.right
                display: none

@media(min-width: 321px)
    .wrapper
        width: 100%
        padding: 40px 20px
        // height: 630px
        display: flex
        flex-direction: column
        font-weight: 600
        gap: 0px
        &>.block_subheader
            margin-bottom: 20px
            font-weight: 700
            font-size: 25px
            display: flex
            flex-direction: row
            gap: 15px
            // align-items: center
            &>a
                text-decoration: none
                display: flex
                align-items: center
                &>button
                    cursor: pointer
                    width: 70px
                    height: 13px
                    font-size: 8px
                    color: #8B8B8B
                    border: 2px solid #8B8B8B
                    font-weight: 600
                    background-color: transparent
                    &:hover
                        color: black
                        border: 2px solid black
        &>.slider
            // padding: 0 82px
            height: 430px
            width: 100%
            margin: 0 auto
            position: relative
            overflow-x: hidden
            &>.trigger
                position: absolute
                font-size: 50px
                top: 35%
                cursor: pointer
                z-index: 100
                opacity: 0
                transition: opacity 0.5s ease
                &:hover
                    opacity: 1
            &>.left
                display: none
            &>.right
                display: none                

@media(min-width: 576px)
    .wrapper
        padding: 40px 30px
        height: 570px
        display: flex
        flex-direction: column
        font-weight: 600
        gap: 20px
        &>.block_subheader
            margin-bottom: 20px
            font-weight: 700
            font-size: 25px
            display: grid
            grid-template-columns: repeat(2, 140px)
            gap: 15px
            align-items: center
            &>a
                text-decoration: none
                display: flex
                align-items: center
                &>button
                    cursor: pointer
                    width: 70px
                    height: 13px
                    font-size: 8px
                    color: #8B8B8B
                    border: 2px solid #8B8B8B
                    font-weight: 600
                    background-color: transparent
                    &:hover
                        color: black
                        border: 2px solid black
        &>.slider
            padding: 0 82px
            height: 430px
            width: 350px
            margin: 0 auto
            position: relative
            overflow-x: hidden
            &>.trigger
                color: #4b5147
                position: absolute
                font-size: 50px
                top: 35%
                cursor: pointer
                z-index: 100
                opacity: 1
                transition: opacity 0.5s ease

@media(min-width: 768px)
    .wrapper
        height: 600px
        &>.block_subheader
            grid-template-columns: repeat(2, 140px)
            gap: 18px
            font-size: 25px
            &>a
                &>button
                    width: 90px
                    height: 20px
                    font-size: 9px
        &>.slider
            width: 708px
            &>.left
                display: block
                left: 10px
                transition: all 0.5s
                &:hover
                    transform: scale(1.1)
                    color: #006300
            &>.right
                display: block
                right: 10px
                transition: all 0.5s
                &:hover
                    transform: scale(1.1)
                    color: #006300

@media(min-width: 992px)
    .wrapper
        height: 570px
        &>.block_subheader
            grid-template-columns: repeat(2, 170px)
            gap: 20px
            font-size: 30px
            &>a
                &>button
                    font-size: 10px
                    width: 100px
                    height: 20px
                    font-size: 11px
        &>.slider
            width: 932px
            height: 650px

@media(min-width: 1200px)
    .wrapper
        height: 600px
        &>.block_subheader
            grid-template-columns: repeat(2, 230px)
            font-size: 40px
            gap: 20px
            &>a
                &>button
                    width: 100px
                    height: 25px
                    font-size: 12px
        &>.slider
            width: 1140px

@media(min-width: 1440px)
    .wrapper
        &>.block_subheader
            gap: 26px
            &>a
                &>button
                    font-size: 12px
                    width: 120px
                    height: 30px
        &>.slider
            width: 1366px
