@media(max-width: 320px)
    .category_container
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        cursor: pointer
        text-decoration: none
        color: black
        transition: all 0.5s ease
        &:hover p
            color: #339933
        .photo_category_container
            max-width: 280px
            height: 300px
            &>img
                height: 100%
                width: 100%
                object-fit: cover
        &>p
            font-size: 20px
            font-weight: 600
            margin: 20px

@media(min-width: 321px)
    .category_container
        width: 100%
        // height: 600px
        display: flex
        flex-direction: column
        align-items: center
        cursor: pointer
        text-decoration: none
        color: black
        transition: all 0.5s ease
        &:hover p
            color: #339933
        .photo_category_container
            width: 80%
            height: 360px
            &>img
                height: 100%
                width: 100%
                object-fit: cover
        &>p
            font-size: 20px
            font-weight: 600
            margin: 20px

@media(min-width: 576px)
    .block
        margin: 0 auto
        margin-bottom: 10px
        padding-bottom: 10px
        width: 360px
        gap: 20px
        transition: all 0.5s ease
        &:hover
            transform: scale(1.1)
            box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px
    .slider
        gap: 30px
    .category_container
        display: flex
        flex-direction: column
        align-items: center
        cursor: pointer
        text-decoration: none
        color: black
        transition: all 0.5s ease
        &:hover p
            color: #339933
        .photo_category_container
            width: 350px
            height: 350px
            &>img
                height: 100%
                width: 100%
                object-fit: cover
        &>p
            font-size: 18px
            font-weight: 600
            margin-bottom: 5px

@media(min-width: 768px)
    .slider
        gap: 20px
    .block
        padding: 0px
        gap: 20px
    .category_container
        width: 320px
        &>.photo_category_container
            width: 320px

@media(min-width: 992px)
    .block
        width: 300px
        padding: 10px 0px
    .slider
        width: 280px
        padding: 0px
        gap: 20px
    .category_container
        &>.photo_category_container
            width: 280px

@media(min-width: 1200px)
    .category_container
        width: 318px
        &>.photo_category_container
            width: 318px
