@media(min-width: 576px)
    .wrapper
        color: #3c4139
        .subheader
            display: flex
            flex-direction: column
            align-items: center
            gap: 20px
            &>p
                text-align: center
                font-size: 30px
            .title_subheader
                font-size: 40px
                font-weight: 700