@media (max-width: 320px)
    .filtr_container
        display: flex
        flex-direction: column
        font-weight: 600
        font-size: 12px
        gap: 10px
        .filt_block
            width: 100%
            display: flex
            flex-direction: column
            gap: 10px
            .price_filtr
                width: 100%
                display: grid
                grid-template-columns: 15% 1fr 
                align-items: center
                justify-content: space-between
                &>.input_wrapper
                    width: 100%
                    display: flex
                    gap: 10px
                    &>input
                        border: 1px solid #000000
                        border-radius: 5px
                        width: 50%
                        height: 42px
                        padding: 0 10px
                        &>::placeholder
                            color: #ACACAC
            .discount_filtr
                grid-row-start: 1
                grid-row-end: 3
                grid-column-start: 2
                display: flex
                justify-content: space-between
                align-items: center
                gap: 15px
                &>input
                    padding: 0 10px
                    width: 40px
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
                    accent-color: #339933
            .sort
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                // gap: 17px
                &>select
                    padding: 0 5px
                    width: 85%
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
        .search_block
            display: flex
            flex-direction: column
            width: 100%
            // align-items: center
            gap: 27px
            .search
                display: flex
                justify-content: space-between
                width: 100%
                align-items: center
                gap: 17px
                label
                    font-size: 22px
                &>input
                    border: 1px solid #000000
                    border-radius: 5px
                    // width: 218px
                    width: 85%
                    height: 42px
                    padding: 0 10px
                    &>::placeholder
                        color: #ACACAC
            &>.btn_container
                width: 100%
                &>button
                    cursor: pointer
                    background-color: #339933
                    padding: 0 10px
                    width: 100%
                    height: 40px
                    border: 1px solid #339933
                    border-radius: 5px
                    color: white
                    transition: all 0.5s ease
                    &:hover
                        background-color: white
                        color: #339933
                        border: 1px solid #339933

@media (min-width: 321px)
    .filtr_container
        display: flex
        width: 100%
        flex-direction: column
        font-weight: 600
        font-size: 12px
        gap: 10px
        .filt_block
            display: flex
            flex-direction: column
            width: 100%
            gap: 10px
            .price_filtr
                // gap: 10px
                width: 100%
                display: flex
                align-items: center
                justify-content: space-between
                &>.input_wrapper
                    width: 85%
                    display: flex
                    gap: 10px
                    &>input
                        border: 1px solid #000000
                        border-radius: 5px
                        width: 50%
                        height: 42px
                        padding: 0 10px
                        &>::placeholder
                            color: #ACACAC
            .discount_filtr
                grid-row-start: 1
                grid-row-end: 3
                grid-column-start: 2
                display: flex
                justify-content: space-between
                align-items: center
                gap: 15px
                &>input
                    padding: 0 10px
                    width: 40px
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
                    accent-color: #339933
            .sort
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                &>select
                    padding: 0 5px
                    width: 85%
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
        .search_block
            margin: 0 auto
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            gap: 27px
            width: 100%
            .search
                display: flex
                justify-content: space-between
                width: 100%
                align-items: center
                gap: 17px
                label
                    font-size: 22px
                &>input
                    border: 1px solid #000000
                    border-radius: 5px
                    // width: 218px
                    width: 85%
                    height: 42px
                    padding: 0 10px
                    &>::placeholder
                        color: #ACACAC
            .btn_container
                width: 100%
                display: flex 
                align-items: center
                justify-content: center
                &>button
                    cursor: pointer
                    background-color: #339933
                    padding: 0 10px
                    // width: 100%
                    width: 350px
                    height: 40px
                    border: 1px solid #339933
                    border-radius: 5px
                    color: white
                    transition: all 0.5s ease
                    &:hover
                        background-color: white
                        color: #339933
                        border: 1px solid #339933

@media (min-width: 576px)
    .filtr_container
        width: 100%
        display: flex
        flex-direction: column
        font-weight: 600
        font-size: 12px
        gap: 10px
        .filt_block
            display: grid
            grid-template-columns: 50% 1fr
            gap: 10px 27px
            .price_filtr
                display: grid
                grid-template-columns: 1fr 85%
                align-items: center
                &>.input_wrapper
                    width: 100%
            .discount_filtr
                grid-row-start: 1
                grid-row-end: 3
                grid-column-start: 2
                display: flex
                flex-direction: row
                justify-content: flex-start
                &>input
                    padding: 0 10px
                    width: 40px
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
                    accent-color: #339933
            .sort
                display: flex
                align-items: center
                gap: 17px
                &>select
                    padding: 0 5px
                    width: 85%
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
        .search_block
            display: grid
            grid-template-columns: 50% 1fr
            align-items: center
            gap: 27px
            .search
                display: flex
                justify-content: space-between
                align-items: center
                gap: 23px
                label
                    font-size: 22px
                &>input
                    border: 1px solid #000000
                    border-radius: 5px
                    width: 85%
                    height: 42px
                    padding: 0 10px
                    &>::placeholder
                        color: #ACACAC
            .btn_container
                width: 185px
                // margin: 0 auto
                display: flex 
                align-items: center
                // justify-content: flex-end
                &>button
                    cursor: pointer
                    background-color: #339933
                    padding: 0 10px
                    width: 100%
                    height: 40px
                    border: 1px solid #339933
                    border-radius: 5px
                    color: white
                    transition: all 0.5s ease
                    &:hover
                        background-color: white
                        color: #339933
                        border: 1px solid #339933

@media (min-width: 768px)
    .filtr_container
        gap: 20px
        .filt_block
            display: flex
            flex-direction: row
            justify-content: space-between
            font-size: 13px
            gap: 15px
            align-items: center
            .price_filtr
                gap: 10px
                &>.input_wrapper
                    &>input
                        font-size: 10px
                        height: 27px
            .discount_filtr
                margin-left: 8%
                gap: 10px
            .sort
                gap: 10px
                &>select
                    // width: 150px
                    height: 27px
        .search_block
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            .search
                gap: 25px
                width: 63%
                &>input
                    width: 100%
                    font-size: 10px
                    height: 27px
                    &>::placeholder
                        color: #ACACAC
            &>.btn_container
                width: 30%
                button
                    // width: 190px
                    height: 27px

@media (min-width: 992px)
    .filtr_container
        width: 80%
        gap: 20px
        .filt_block
            font-size: 15px
            .price_filtr
                &>input
                    border-radius: 7px
                    height: 30px
            .discount_filtr
                &>input
                    border-radius: 7px
                    // height: 30px
                    // width: 30px
            .sort
                &>select
                    border-radius: 7px
                    width: 180px
                    height: 30px
        .search_block
            .search
                &>input
                    border-radius: 7px
                    height: 30px
            &>button
                border-radius: 7px
                width: 180px
                height: 30px

@media (min-width: 1200px)
    .filtr_container
        width: 70%
        gap: 35px
        .filt_block
            font-size: 20px
            .price_filtr
                &>.input_wrapper
                    &>input
                        font-size: 15px
                        border-radius: 8px
                        height: 40px
            &>.discount_filtr
                &>input
                    border-radius: 8px
                    height: 60px
                    width: 60px
            .sort
                &>select
                    border-radius: 8px
                    width: 180px
                    height: 40px
                    font-size: 15px
        .search_block
            .search
                width: 65%
                gap: 35px
                label
                    font-size: 30px
                &>input
                    font-size: 15px
                    border-radius: 8px
                    height: 40px
            &>.btn_container
                &>button
                    font-size: 20px
                    border-radius: 8px
                    width: 240px
                    height: 40px

@media (min-width: 1440px)
    .filtr_container
        .filt_block
            .price_filtr
                &>input
                    width: 103px
            .sort
                &>select
                    width: 280px
        .search_block
            &>.search
                width: 63%
            &>.btn_container
                display: flex
                justify-content: flex-end
                width: 1fr
                &>button
                    width: 95%
