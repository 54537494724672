@media(max-width: 429px)
    .form_item
        padding: 20px
        width: 100%
        &>a
            text-decoration: none
        .form_title
            font-weight: 800
            font-size: 28px
            line-height: 32px
            margin-bottom: 24px
        .info_text
            font-size: 12px
            font-weight: 400
            color: #A7A7A7
            margin: 24px 0

@media(min-width: 430px)
    .form_item
        padding: 20px
        width: 420px
        &>a
            text-decoration: none
        .form_title
            font-weight: 800
            font-size: 28px
            line-height: 32px
            margin-bottom: 24px
        .info_text
            font-size: 12px
            font-weight: 400
            color: #A7A7A7
            margin: 24px 0

