@media(max-width: 320px)
    .header
        max-width: 320px
        position: relative
        height: 60px
        display: flex
        justify-content: space-between
        align-items: center
        &>.header_main
            display: flex
            align-items: center
            justify-content: center
            gap: 20px
            &>a .header_logo
                height: 40px
                width: 40px
            &>a .btn_log
                height: 40px
                width: 80px
                background: #339933
                border: 2px solid #339933
                color: #FFFFFF
                border-radius: 5px
                font-weight: 700
                font-size: 14px
                cursor: pointer
                transition: color 0.5s, background-color 0.5s
                &:hover
                    color: #339933
                    background-color: #FFFFFF
                    border-radius: 1px solid #339933
        &>.header_menu
            display: flex
            flex-direction: row-reverse
            gap: 20px
            align-items: center
            justify-content: space-between
            &>.menu_body
                display: block
                // &>.active_link
                //     z-index: 1
                //     transform: translateX(0)
                //     overflow: hidden
                &>.burger_menu
                    display: flex
                    align-items: center
                    border: none
                    background-color: transparent
                    cursor: pointer
                    transition: all 0.5s ease
                    color: #3c4139
                    &>.icon_burger
                        color: #3c4139
                        font-size: 30px
                        display: block
                        &:hover
                            color: #339933
                    &>.icon_close
                        // color: #3c4139
                        display: none
                        font-size: 30px
                        &:hover
                            color: #339933
                &>.active
                    &>.icon_burger
                        display: none
                    &>.icon_close
                        display: block
                &>.menu_list
                    position: absolute
                    display: flex
                    flex-direction: column
                    padding: 10px 30px
                    transform: translateX(150%)
                    right: 0px
                    top: 100%
                    transition: transform 0.5s ease
                    background-color: white
                    border-radius: 0px 0px 0px 5px
                    font-size: 20px
                    &>a
                        color: #3c4139
                        text-decoration: none
                        font-weight: 600
                        list-style-type: none
                        cursor: pointer
                        padding: 7px 0
                        transition: all 0.5s ease
                    &>a:hover
                        transform: scale(1.2)
                        color: #339933
                    &>.active
                        font-weight: 600
                        color: #339933
                        &>a
                            font-weight: 600
                            color: #339933
                &>.active_link
                    z-index: 0
                    transform: translateX(0)
                    overflow: hidden
            &>a
                color: #3c4139
                border: none
                background-color: transparent
                cursor: pointer
                position: relative
                &.active
                    color: #339933
                    font-weight: 600
                &>.icon_bag
                    font-size: 30px
                    &:hover
                        color: #339933
                &>.count_basket_prod
                    position: absolute
                    top: -5px
                    right: -10px

@media(min-width: 321px)
    .header
        height: 70px
        position: relative
        height: 60px
        display: flex
        justify-content: space-between
        align-items: center
        &>.header_main
            display: flex
            align-items: center
            justify-content: center
            gap: 20px
            &>a .header_logo
                height: 45px
                width: 45px
            &>a .btn_log
                width: 110px
                height: 40px
                background: #339933
                border: 2px solid #339933
                color: #FFFFFF
                border-radius: 5px
                font-weight: 700
                font-size: 14px
                cursor: pointer
                transition: color 0.5s, background-color 0.5s
                &:hover
                    color: #339933
                    background-color: #FFFFFF
                    border-radius: 1px solid #339933
        &>.header_menu
            gap: 40px
            display: flex
            flex-direction: row-reverse
            align-items: center
            justify-content: space-between
            &>.menu_body
                display: block
                &>.burger_menu
                    display: flex
                    align-items: center
                    border: none
                    background-color: transparent
                    cursor: pointer
                    transition: all 0.5s ease
                    color: #3c4139
                    &>.icon_burger
                        color: #3c4139
                        font-size: 30px
                        display: block
                        &:hover
                            color: #339933
                    &>.icon_close
                        // color: #3c4139
                        display: none
                        font-size: 30px
                        &:hover
                            color: #339933
                &>.active
                    &>.icon_burger
                        display: none
                    &>.icon_close
                        display: block
                &>.menu_list
                    font-size: 16px
                    position: absolute
                    display: flex
                    flex-direction: column
                    padding: 10px 30px
                    transform: translateX(150%)
                    right: 0px
                    top: 100%
                    transition: transform 0.5s ease
                    background-color: white
                    border-radius: 0px 0px 0px 5px
                    font-size: 20px
                    &>a
                        color: #3c4139
                        text-decoration: none
                        font-weight: 600
                        list-style-type: none
                        cursor: pointer
                        padding: 7px 0
                        transition: all 0.5s ease
                    &>a:hover
                        transform: scale(1.2)
                        color: #339933
                    &>.active
                        font-weight: 600
                        color: #339933
                        &>a
                            font-weight: 600
                            color: #339933
                &>.active_link
                    z-index: 0
                    transform: translateX(0)
            &>a
                color: #3c4139
                border: none
                background-color: transparent
                cursor: pointer
                position: relative
                &.active
                    color: #339933
                    font-weight: 600
                &>.icon_bag
                    font-size: 30px
                    &:hover
                        color: #339933
                &>.count_basket_prod
                    position: absolute
                    top: -5px
                    right: -10px

@media(min-width: 576px)
    .header
        position: relative
        height: 70px
        display: flex
        justify-content: space-between
        align-items: center
        &>.header_main
            display: flex
            align-items: center
            justify-content: center
            gap: 20px
            &>a .header_logo
                height: 50px
                width: 50px
            &>a .btn_log
                height: 40px
                width: 130px
                background: #339933
                border: 2px solid #339933
                color: #FFFFFF
                border-radius: 5px
                font-weight: 700
                font-size: 16px
                cursor: pointer
                transition: color 0.5s, background-color 0.5s
                &:hover
                    color: #339933
                    background-color: #FFFFFF
                    border-radius: 1px solid #339933
        &>.header_menu
            display: flex
            flex-direction: row-reverse
            gap: 56px
            align-items: center
            justify-content: space-between
            &>.menu_body
                display: block
                &>.burger_menu
                    display: flex
                    align-items: center
                    border: none
                    background-color: transparent
                    cursor: pointer
                    transition: all 0.5s ease
                    &>.icon_burger
                        font-size: 30px
                        display: block
                        &:hover
                            color: #339933
                    &>.icon_close
                        display: none
                        font-size: 30px
                        &:hover
                            color: #339933
                &>.active
                    &>.icon_burger
                        display: none
                    &>.icon_close
                        display: block
                &>.menu_list
                    position: absolute
                    display: flex
                    flex-direction: column
                    padding: 10px 30px
                    transform: translateX(150%)
                    right: 0px
                    top: 100%
                    transition: transform 0.5s ease
                    background-color: white
                    border-radius: 0px 0px 0px 5px
                    font-size: 20px
                    &>a
                        color: #3c4139
                        text-decoration: none
                        font-weight: 600
                        list-style-type: none
                        cursor: pointer
                        padding: 7px 0
                        transition: all 0.5s ease
                    &>a:hover
                        transform: scale(1.2)
                        color: #339933
                    &>.active
                        font-weight: 600
                        color: #339933
                        &>a
                            font-weight: 600
                            color: #339933
                &>.active_link
                    z-index: 0
                    transform: translateX(0)
            &>a
                color: #3c4139
                border: none
                background-color: transparent
                cursor: pointer
                position: relative
                &.active
                    color: #339933
                    font-weight: 600
                &>.icon_bag
                    font-size: 30px
                    &:hover
                        color: #339933
                &>.count_basket_prod
                    position: absolute
                    top: -5px
                    right: -10px

@media(min-width: 768px)
    .header
        &>.header_main
            gap: 60px

@media(min-width: 992px)
    .header
        height: 100px
        &>.header_main
            gap: 30px
            &>a .header_logo
                height: 72px
                width: 72px
            &>.btn_catalog
                height: 50px
        &>.header_menu
            height: 50px
            display: flex
            flex-direction: row
            gap: 20px
            &>.menu_body
                &>.burger_menu
                    display: none
                &>.menu_list
                    position: relative
                    transform: translateX(0)
                    height: 50px
                    display: flex
                    flex-direction: row
                    align-items: center
                    justify-content: space-between
                    gap: 20px
                    &>li
                        border: none

@media(min-width: 1200px)
    .header
        &>.header_main
            gap: 60px
        &>.header_menu
            gap: 55px
            &>.menu_body
                &>.menu_list
                    gap: 57px

@media(min-width: 1440px)
    .header
        &>.header_menu
            &>.menu_body
                &>.menu_list
                    padding: 10px 0
