@media(max-width: 575px)
    .wrapper
        padding-top: 20px
        display: flex
        flex-direction: column
        gap: 40px
        &>.subheader
            color: #3c4139
            font-size: 20px
            font-weight: 700

@media(min-width: 576px)
    .wrapper
        padding-top: 20px
        display: flex
        flex-direction: column
        // gap: 40px
        gap: 60px
        &>.subheader
            color: #3c4139
            font-size: 20px
            font-weight: 700

@media(min-width: 768px)
    .wrapper
        padding-top: 20px
        &>.subheader
            font-size: 25px

@media(min-width: 992px)
    .wrapper
        padding-top: 40px
        min-height: calc(100vh - 170px)
        &>.subheader
            font-size: 30px

@media(min-width: 1200px)
    .wrapper
        padding-top: 50px
        &>.subheader
            font-size: 40px

@media(min-width: 1440px)
    .wrapper
        padding-top: 60px
