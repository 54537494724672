@media(min-width: 576px)
    .btn_home
        height: 50px
        width: 150px
        border-radius: 7px
        background: #339933
        border: 2px solid #339933
        color: #FFFFFF
        font-weight: 700
        font-size: 16px
        cursor: pointer
        transition: color 0.5s, background-color 0.5s
        &:hover
            color: #339933
            background-color: #FFFFFF
            border-radius: 1px solid #339933

@media(min-width: 768px)
    .btn_home
        height: 60px
        width: 250px
        border-radius: 10px
        font-size: 18px

@media(min-width: 992px)
    .btn_home
        height: 70px
        width: 350px
        border-radius: 13px
        font-size: 23px

@media(min-width: 1200px)
    .btn_home
        height: 80px
        width: 400px
        font-size: 25px

@media(min-width: 1440px)
    .btn_home
        height: 85px
        width: 450px
        font-size: 28px
