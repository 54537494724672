@media(max-width: 575px)
    .form_container
        display: flex
        flex-direction: column
        gap: 20px
        &>input
            font-weight: 600
            color: #6C6C6C
            font-size: 15px
            padding: 0 30px
            border-radius: 18px
            height: 45px
            border: 1px solid #FFFFFF
        &>button
            cursor: pointer
            background-color: transparent
            border-radius: 18px
            height: 45px
            border: 1px solid #FFFFFF
            font-size: 20px
            font-weight: 700
            color: #FFFFFF
            transition: color 0.5s, background-color 0.5s
            &:hover
                color: black
                background-color: #FFFFFF
    
@media(min-width: 576px)
    .form_container
        display: flex
        flex-direction: column
        gap: 20px
        &>input
            font-weight: 600
            color: #6C6C6C
            font-size: 15px
            padding: 0 30px
            border-radius: 18px
            height: 45px
            border: 1px solid #FFFFFF
        &>button
            cursor: pointer
            background-color: transparent
            border-radius: 18px
            height: 45px
            border: 1px solid #FFFFFF
            font-size: 20px
            font-weight: 700
            color: #FFFFFF
            transition: color 0.5s, background-color 0.5s
            &:hover
                color: black
                background-color: #FFFFFF

@media(min-width: 768px)
    .form_container
        &>input
            font-size: 15px
            border-radius: 20px
            height: 55px
        &>button
            border-radius: 20px
            height: 55px
            font-size: 23px

@media(min-width: 992px)
    .form_container
        &>input
            font-size: 16px
            border-radius: 23px
            height: 65px
            padding: 0 40px
            border: 2px solid #FFFFFF
        &>button
            border-radius: 23px
            height: 65px
            font-size: 28px
            border: 2px solid #FFFFFF

@media(min-width: 1200px)
    .form_container
        gap: 30px
        &>input
            font-size: 18px
            border-radius: 25px
            height: 75px
            padding: 0 50px
        &>button
            border-radius: 25px
            height: 75px
            font-size: 30px
