@media(max-width: 575px)
    .btn
        height: 50px
        width: 240px
        background: transparent
        border: 1px solid white
        border-radius: 7px
        color: white
        font-weight: 700
        font-size: 16px
        cursor: pointer
        transition: color 0.5s, background-color 0.5s
        &:hover
            background-color: #FFFFFF
            color: #2D2D2D

@media(min-width: 576px)
    .btn
        height: 40px
        width: 120px
        background: transparent
        border: 1px solid white
        border-radius: 7px
        color: white
        font-weight: 700
        cursor: pointer
        transition: color 0.5s, background-color 0.5s
        &:hover
            background-color: #FFFFFF
            color: #2D2D2D

@media(min-width: 768px)
    .btn
        height: 50px
        width: 160px
        border-radius: 10px
        font-size: 18px

@media(min-width: 992px)
    .btn
        height: 70px
        width: 210px
        border-radius: 13px
        font-size: 23px

@media(min-width: 1200px)
    .btn
        height: 80px
        width: 240px
        font-size: 25px
