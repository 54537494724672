// @media(min-width: 576px)
//     .wrapper
//         margin: 30px auto
//         display: grid
//         grid-template-columns: repeat(2, 1fr)
//         gap: 80px

// @media(min-width: 992px)
//     .wrapper
//         gap: 100px

.wrapper
    padding: 0px 0px
    // height: 70px
    .footer_text
        background-color: #339933
        font-weight: 600
        padding: 20px 0px
        color: #3c4139
        text-align: center
            
