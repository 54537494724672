a
    text-decoration: none
    &:hover
        opacity: .9
    .btn_quick_nav
        position: fixed
        border-radius: 50%
        border: 1px solid #3c4139 
        background-color: #4eae17
        font-size: 25px
        cursor: pointer
        width: 48px
        height: 48px
        bottom: 20px
        color: #3c4139
