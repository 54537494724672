@media(max-width: 420px)
    .form_input
        display: flex
        flex-direction: column
        align-items: flex-start
        border-radius: 12px
        margin: 4px auto
        border: 1px solid #A7A7A7
        width: 100%
        height: 50px
        padding: 14px 16px
        &:focus
            border: 1px solid #339933
            outline: none

@media(min-width: 421px)
    .form_input
        display: flex
        flex-direction: column
        align-items: flex-start
        border-radius: 12px
        margin: 4px auto
        border: 1px solid #A7A7A7
        width: 350px
        height: 50px
        padding: 14px 16px
        &:focus
            border: 1px solid #339933
            outline: none
