@media(max-width: 400px)
    .basket_item
        padding: 40px 0px 20px
        display: flex
        flex-direction: column
        align-items: center
        gap: 10px
        border-bottom: 2px solid #A7A7A7
        &:hover
            position: relative
        &:hover .close_icon
            display: block
        &>.close_icon
            cursor: pointer
            display: none
            position: absolute
            top: 10px
            right: 0px
            color: #A7A7A7
            transition: transform 0.5s
            &:hover
                transform: scale(1.5)
                color: black
        &>.product_img
            width: 100%
            height: 100%
            object-fit: cover
            &>img
                width: 100%
                height: 100%
                border-radius: 6px
        &>.product_info
            width: 100%
            display: grid
            grid-template-columns: 1fr 25%
            align-items: center
            justify-content: space-between
            gap: 10%
            font-size: 12px
            &>.product_count
                height: 20px
                border-radius: 6px
                border: 1px solid #000000
                display: flex
                align-items: center
                justify-content: space-between
                padding: 15px
                font-size: 12px
                color: #000000
                &>button
                    color: #000000
                    font-size: 12px
                    cursor: pointer
                    background-color: transparent
                    border: none
                    transition: transform 0.5s
                    &:hover
                        transform: scale(2)
        &>.product_price
            display: flex
            align-items: center
            justify-content: flex-start
            gap: 10%
            width: 100%
            &>.clear_price
                font-size: 22px
                font-weight: 600
                font-size: 20px
            &>.discount_price
                font-size: 22px
                font-weight: 600
                font-size: 20px
            &>.price
                font-size: 12px
                font-weight: 500
                text-decoration: line-through
                color: #8B8B8B

@media(min-width: 401px)
    .basket_item
        padding: 20px 0
        display: grid
        grid-template-columns: 25% 35% 30%
        align-items: center
        gap: 20px
        border-bottom: 2px solid #A7A7A7
        &:hover
            position: relative
        &:hover .close_icon
            display: block
        &>.close_icon
            cursor: pointer
            display: none
            position: absolute
            top: 10px
            right: 10px
            color: #A7A7A7
            transition: transform 0.5s
            &:hover
                transform: scale(1.5)
                color: black
        &>.product_img
            width: 100px
            height: 100px
            object-fit: cover
            &>img
                width: 100%
                height: 100%
                border-radius: 6px
        &>.product_info
            display: flex
            height: 100%
            gap: 10px
            flex-direction: column
            justify-content: space-between
            font-size: 12px
            &>.product_count
                height: 20px
                width: 80px
                border-radius: 6px
                border: 1px solid #000000
                display: flex
                align-items: center
                justify-content: space-between
                padding: 15px
                font-size: 12px
                color: #000000
                &>button
                    color: #000000
                    font-size: 10px
                    cursor: pointer
                    background-color: transparent
                    border: none
                    transition: transform 0.5s
                    &:hover
                        transform: scale(2)
        &>.product_price
            display: flex
            align-items: center
            justify-content: space-between
            gap: 20px
            &>.clear_price
                font-size: 22px
                font-weight: 600
                font-size: 20px
            &>.discount_price
                font-size: 22px
                font-weight: 600
                font-size: 20px
            &>.price
                font-size: 12px
                font-weight: 500
                text-decoration: line-through
                color: #8B8B8B

@media(min-width: 576px)
    .basket_item
        padding: 20px 0
        display: grid
        grid-template-columns: 25% 45% 20%
        align-items: center
        gap: 20px
        border-bottom: 2px solid #A7A7A7
        &:hover
            position: relative
        &:hover .close_icon
            display: block
        &>.close_icon
            cursor: pointer
            display: none
            position: absolute
            top: 10px
            right: 10px
            color: #A7A7A7
            transition: transform 0.5s
            &:hover
                transform: scale(1.5)
                color: black
        &>.product_img
            width: 100%
            height: 100%
            object-fit: cover
            &>img
                width: 100%
                height: 100%
                border-radius: 6px
        &>.product_info
            display: flex
            height: 100%
            flex-direction: column
            justify-content: space-between
            align-items: center
            text-align: center
            font-size: 14px
            &>.product_count
                height: 20px
                width: 35%
                border-radius: 6px
                border: 1px solid #000000
                display: flex
                align-items: center
                justify-content: space-between
                // gap: 30px
                padding: 15px
                font-size: 12px
                &>button
                    font-size: 10px
                    cursor: pointer
                    background-color: transparent
                    border: none
                    transition: transform 0.5s
                    &:hover
                        transform: scale(2)
        &>.product_price
            display: flex
            align-items: center
            justify-content: flex-end
            gap: 20px
            &>.clear_price
                font-size: 22px
                font-weight: 600
                font-size: 20px
            &>.discount_price
                font-size: 22px
                font-weight: 600
                font-size: 20px
            &>.price
                font-size: 12px
                font-weight: 500
                text-decoration: line-through
                color: #8B8B8B

@media(min-width: 768px)
    .basket_item
        padding: 30px 0
        gap: 20px
        &>.product_img
            &>img
                border-radius: 10px
        &>.product_info
            font-size: 15px
            &>.product_count
                height: 25px
                width: 90px
                border-radius: 9px
                font-size: 15px
                &>button
                    font-size: 15px
        &>.product_price
            gap: 15px
            &>.clear_price
                font-size: 25px
            &>.discount_price
                font-size: 25px
            &>.price
                font-size: 15px

@media(min-width: 992px)
    .basket_item
        &>.product_img
            &>img
                border-radius: 10px
        &>.product_info
            text-align: start
            align-items: flex-start
            font-size: 15px
            &>.product_count
                height: 30px
                width: 100px
                border-radius: 10px
                font-size: 15px
                &>button
                    font-size: 15px
        &>.product_price
            gap: 35px
            &>.clear_price
                font-size: 30px
            &>.discount_price
                font-size: 30px
            &>.price
                font-size: 15px

@media(min-width: 1200px)
    .basket_item
        &>.product_img
            &>img
                border-radius: 11px
        &>.product_info
            font-size: 17px
            &>.product_count
                height: 35px
                width: 120px
                border-radius: 11px
                font-size: 17px
                &>button
                    font-size: 17px
        &>.product_price
            gap: 35px
            &>.clear_price
                font-size: 40px
            &>.discount_price
                font-size: 40px
            &>.price
                font-size: 20px

@media(min-width: 1440px)
    .basket_item
        &>.product_img
            &>img
                border-radius: 12px
        &>.product_info
            font-size: 18px
            &>.product_count
                height: 40px
                width: 140px
                border-radius: 12px
                font-size: 18px
                &>button
                    font-size: 18px
        &>.product_price
            gap: 40px
