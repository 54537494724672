@media(max-width: 320px)    
    .footer_contact
        background-color: #f6f6f6
        padding-top: 30px 
        margin-top: 30px 
        display: flex
        flex-direction: column
        align-items: center
        color: #339933
        &>p
            text-align: center
            font-weight: 700
            font-size: 30px
            text-shadow: -1px -1px 0px #3c4139
        .social_network_icons
            display: flex
            align-items: center
            justify-content: center
            gap: 40%
            margin-top: 20px
            padding-bottom: 10px
            .instagram_icon
                color: #3c4139
                text-align: center
                font-size: 40px
                transition: all 0.5s ease
                &>p
                    font-size: 15px
                &:hover
                    transform: scale(1.2)
                    color: #339933
            .whats_up_icon
                color: #3c4139
                text-align: center
                font-size: 40px
                transition: all 0.5s ease
                &>p
                    font-size: 15px
                &:hover
                    transform: scale(1.2)
                    color: #339933

@media(min-width: 321px) 
    .footer_contact
        background-color: #f6f6f6
        padding-top: 30px 
        margin-top: 30px 
        display: flex
        flex-direction: column
        align-items: center
        color: #339933
        &>p
            text-align: center
            font-weight: 700
            font-size: 40px
            text-shadow: -1px -1px 0px #3c4139
        .social_network_icons
            display: flex
            align-items: center
            justify-content: center
            gap: 40%
            margin-top: 20px
            padding-bottom: 10px
            .instagram_icon
                color: #3c4139
                text-align: center
                font-size: 40px
                transition: all 0.5s ease
                &>p
                    font-size: 15px
                &:hover
                    transform: scale(1.2)
                    color: #339933
            .whats_up_icon
                color: #3c4139
                text-align: center
                font-size: 40px
                transition: all 0.5s ease
                &>p
                    font-size: 15px
                &:hover
                    transform: scale(1.2)
                    color: #339933

@media(min-width: 576px)    
    .footer_contact
        background-color: #f6f6f6
        padding-top: 30px 
        margin-top: 30px 
        display: flex
        flex-direction: column
        align-items: center
        color: #339933
        .social_network_icons
            display: flex
            align-items: center
            gap: 40%
            margin-top: 20px
            padding-bottom: 10px
            .instagram_icon
                color: #3c4139
                text-align: center
                font-size: 40px
                transition: all 0.5s ease
                &>p
                    font-size: 15px
                &:hover
                    transform: scale(1.2)
                    color: #339933
            .whats_up_icon
                color: #3c4139
                text-align: center
                font-size: 40px
                transition: all 0.5s ease
                &>p
                    font-size: 15px
                &:hover
                    transform: scale(1.2)
                    color: #339933
    
    // @media(min-width: 576px)
//     .wrapper
//         display: flex
//         flex-direction: column
//         gap: 25px
//         &>.subheader
//             font-size: 20px
//             font-weight: 700
//         &>p
//             font-size: 25px
//             font-weight: 600
//         &>.social_network_icons
//             margin-top: 13px
//             font-size: 25px
//             display: flex
//             align-items: center
//             gap: 30px
//             &>.instagram_icon
//                 cursor: pointer
//                 display: flex
//                 flex-direction: column
//                 gap: 5px
//                 text-decoration: none
//                 color: black
//                 transition: all 0.5s ease
//                 &:hover
//                     transform: scale(1.1)
//                     color: #339933
//                 &>p
//                     font-size: 13px
//             &>.whats_up_icon
//                 cursor: pointer
//                 display: flex
//                 flex-direction: column
//                 gap: 5px
//                 text-decoration: none
//                 color: black
//                 transition: all 0.5s ease
//                 &:hover
//                     transform: scale(1.1)
//                     color: #339933
//                 &>p
//                     font-size: 13px

// @media(min-width: 768px)
//     .wrapper
//         gap: 20px
//         &>.subheader
//             font-size: 25px
//         &>p
//             font-size: 35px
//         &>.social_network_icons
//             font-size: 30px
//             margin-top: 25px

// @media(min-width: 992px)
//     .wrapper
//         &>.subheader
//             font-size: 30px
//         &>p
//             font-size: 47px
//         &>.social_network_icons
//             font-size: 35px
//             margin-top: 20px
//             &>.instagram_icon>p
//                 font-size: 15px
//             &>.whats_up_icon>p
//                 font-size: 15px

// @media(min-width: 1200px)
//     .wrapper
//         &>.subheader
//             font-size: 40px
//         &>p
//             font-size: 60px
//         &>.social_network_icons
//             font-size: 40px
//             gap: 30px
//             &>.instagram_icon
//                 gap: 10px
//             &>.whats_up_icon
//                 gap: 10px

// @media(min-width: 1440px)
//     .wrapper
//         &>p
//             font-size: 70px
