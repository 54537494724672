@media(min-width: 576px)
    .wrapper
        min-height: calc(100vh - 140px)
        padding: 50px 30px
        display: flex
        flex-direction: column
        align-items: center
        gap: 20px
        &>.warning
            font-size: 30px
            text-transform: uppercase
            font-weight: 700
        &>.text
            font-size: 15px
            font-weight: 500
        &>.btn_home
            // margin: 50px auto

@media(min-width: 768px)
    .wrapper
        &>.warning
            font-size: 35px

@media(min-width: 992px)
    .wrapper
        min-height: calc(100vh - 170px)
        &>.warning
            font-size: 45px
        &>.text
            font-size: 15px

@media(min-width: 1200px)
    .wrapper
        &>.warning
            font-size: 55px
        &>.text
            font-size: 20px

@media(min-width: 1440px)
    .wrapper
        &>.warning
            font-size: 60px
        &>.text
            font-size: 25px
