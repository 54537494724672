@media(max-width: 575px)
    .wrapper
        height: 300px
        background-color: #A1E2EB
        color: white
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        &>.sale_container
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            gap: 20px
            &>.title
                display: flex
                max-width: 398px
                flex-direction: column
                align-items: center
                justify-content: center
                font-size: 40px
                font-weight: 700
                &>p
                    text-align: center
                &>.sale_p_elem
                    font-size: 60px
                    font-weight: 700
            &>.btn_container
                display: flex
                gap: 40px
        &>.sale_picture
            display: none
    
@media(min-width: 576px)
    .wrapper
        height: 300px
        background-color: #A1E2EB
        color: white
        display: flex
        align-items: center
        position: relative
        padding-left: 40px
        &>.sale_container
            display: flex
            flex-direction: column
            justify-content: space-between
            gap: 60px
            &>.title
                font-size: 33px
                font-weight: 700
                &>.sale_p_elem
                    font-size: 50px
                    font-weight: 700
            &>.btn_container
                display: flex
                gap: 20px
        &>.sale_picture
            display: block
            top: 20px
            right: 0
            width: 43%
            height: 90%
            object-fit: cover
            position: absolute
            &>img
                height: 100%
                width: 100%

@media(min-width: 768px)
    .wrapper
        height: 400px
        &>.sale_container
            &>.title
                font-size: 43px
                &>.sale_p_elem
                    font-size: 60px

@media(min-width: 992px)
    .wrapper
        height: 500px
        padding-left: 50px
        &>.sale_container
            gap: 50px
            &>.title
                font-size: 53px
                &>.sale_p_elem
                    font-size: 70px

@media(min-width: 1200px)
    .wrapper
        height: 600px
        &>.sale_container
            gap: 60px
            &>.title
                font-size: 63px
                &>.sale_p_elem
                    font-size: 80px

@media(min-width: 1440px)
    .wrapper
        &>.sale_picture
            top: 0px
            width: 53%
            height: 100%
            object-fit: cover
            &>img
                width: 100%
                height: 100%
