@media(max-width: 420px)
    .form_btn
        display: flex
        align-items: center
        justify-content: center
        border-radius: 55px
        padding: 14px 24px
        margin: 4px auto
        width: 100%
        height: 50px
        font-size: 16px
        font-weight: 700
        cursor: pointer
    .green
        background-color: #339933
        border: 1px solid transparent
    .white
        background-color: white
        border: 1px solid #A7A7A7
        
    .form_btn
        display: flex
        align-items: center
        justify-content: center
        border-radius: 25px
        padding: 14px 24px
        margin: 4px auto
        width: 100%
        height: 50px
        font-size: 16px
        font-weight: 700
        cursor: pointer
    .green
        background-color: #339933
        border: 1px solid transparent
    .white
        background-color: white
        border: 1px solid #A7A7A7


@media(min-width: 421px)
    .form_btn
        display: flex
        align-items: center
        justify-content: center
        border-radius: 55px
        padding: 14px 24px
        margin: 4px auto
        width: 350px
        height: 50px
        font-size: 16px
        font-weight: 700
        cursor: pointer
    .green
        background-color: #339933
        border: 1px solid transparent
    .white
        background-color: white
        border: 1px solid #A7A7A7
        
    .form_btn
        display: flex
        align-items: center
        justify-content: center
        border-radius: 55px
        padding: 14px 24px
        margin: 4px auto
        width: 350px
        height: 50px
        font-size: 16px
        font-weight: 700
        cursor: pointer
    .green
        background-color: #339933
        border: 1px solid transparent
    .white
        background-color: white
        border: 1px solid #A7A7A7
