@media (max-width: 320px)
    .filtr_container
        display: flex
        flex-direction: column
        font-weight: 600
        font-size: 12px
        gap: 10px
        .filt_block
            display: flex
            flex-direction: column
            width: 100%
            gap: 10px
            .price_filtr
                // gap: 11px
                width: 100%
                display: flex
                align-items: center
                justify-content: space-between
                &>.input_wrapper
                    width: 85%
                    display: flex
                    gap: 10px
                    &>input
                        border: 1px solid #000000
                        border-radius: 5px
                        width: 50%
                        height: 42px
                        padding: 0 10px
                        &>::placeholder
                            color: #ACACAC
                    // &>label
                    //     padding-right: 16px
            .discount_filtr
                grid-row-start: 1
                grid-row-end: 3
                grid-column-start: 2
                display: flex
                align-items: center
                gap: 15px
                &>input
                    padding: 0 10px
                    width: 40px
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
                    accent-color: #339933
            .sort
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                // gap: 17px
                &>select
                    padding: 0 5px
                    width: 85%
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
        .search_block
            display: flex
            flex-direction: column
            width: 100%
            // align-items: center
            gap: 27px
            .search
                display: flex
                justify-content: space-between
                width: 100%
                align-items: center
                gap: 17px
                label
                    font-size: 22px
                &>input
                    border: 1px solid #000000
                    border-radius: 5px
                    // width: 218px
                    width: 85%
                    height: 42px
                    padding: 0 10px
                    &>::placeholder
                        color: #ACACAC
            &>.btn_container
                width: 100%
                &>button
                    cursor: pointer
                    background-color: #339933
                    padding: 0 10px
                    width: 100%
                    height: 40px
                    border: 1px solid #339933
                    border-radius: 5px
                    color: white
                    transition: all 0.5s ease
                    &:hover
                        background-color: white
                        color: #339933
                        border: 1px solid #339933

@media (min-width: 321px)
    .filtr_container
        display: flex
        width: 100%
        flex-direction: column
        font-weight: 600
        font-size: 12px
        gap: 10px
        .filt_block
            display: flex
            flex-direction: column
            width: 100%
            gap: 10px
            .price_filtr
                // gap: 10px
                width: 100%
                display: flex
                align-items: center
                justify-content: space-between
                &>.input_wrapper
                    width: 85%
                    display: flex
                    gap: 10px
                    &>input
                        border: 1px solid #000000
                        border-radius: 5px
                        width: 50%
                        height: 42px
                        padding: 0 10px
                        &>::placeholder
                            color: #ACACAC
            .discount_filtr
                grid-row-start: 1
                grid-row-end: 3
                grid-column-start: 2
                display: flex
                align-items: center
                gap: 15px
                &>input
                    padding: 0 10px
                    width: 40px
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
                    accent-color: #339933
            .sort
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                // gap: 17px
                &>select
                    padding: 0 5px
                    width: 85%
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
        .search_block
            margin: 0 auto
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            gap: 27px
            width: 100%
            .search
                display: flex
                justify-content: space-between
                width: 100%
                align-items: center
                gap: 17px
                label
                    font-size: 22px
                &>input
                    border: 1px solid #000000
                    border-radius: 5px
                    // width: 218px
                    width: 85%
                    height: 42px
                    padding: 0 10px
                    &>::placeholder
                        color: #ACACAC
            .btn_container
                width: 100%
                display: flex 
                align-items: center
                justify-content: center
                &>button
                    cursor: pointer
                    background-color: #339933
                    padding: 0 10px
                    // width: 100%
                    width: 350px
                    height: 40px
                    border: 1px solid #339933
                    border-radius: 5px
                    color: white
                    transition: all 0.5s ease
                    &:hover
                        background-color: white
                        color: #339933
                        border: 1px solid #339933

@media (min-width: 576px)
    .filtr_container
        width: 100%
        display: flex
        flex-direction: column
        font-weight: 600
        font-size: 12px
        gap: 10px
        .filt_block
            display: flex
            flex-direction: row
            // grid-template-columns: 280px 200px
            width: 100%
            gap: 10px
            .price_filtr
                gap: 11px
                display: flex
                align-items: center
                width: 52%
                &>input
                    border: 1px solid #000000
                    border-radius: 5px
                    width: 43%
                    height: 42px
                    padding: 0 10px
                    &>::placeholder
                        color: #ACACAC
                &>button
                    display: none
            .sort
                display: flex
                align-items: center
                gap: 17px
                width: 50%
                &>select
                    padding: 0 5px
                    // width: 218px
                    height: 40px
                    border: 1px solid #000000
                    border-radius: 5px
        .search_block
            display: flex
            flex-direction: row
            align-items: center
            width: 100%
            gap: 10px
            .search
                width: 50%
                display: flex
                justify-content: space-between
                align-items: center
                gap: 23px
                label
                    font-size: 22px
                &>input
                    border: 1px solid #000000
                    border-radius: 5px
                    // width: 218px
                    height: 42px
                    padding: 0 10px
                    &>::placeholder
                        color: #ACACAC
            &>.btn_container
                width: 48%
                &>button
                    cursor: pointer
                    background-color: #339933
                    padding: 0 10px
                    // width: 187px
                    height: 40px
                    border: 1px solid #339933
                    border-radius: 5px
                    color: white
                    transition: all 0.5s ease
                    &:hover
                        background-color: white
                        color: #339933
                        border: 1px solid #339933

@media (min-width: 768px)
    .filtr_container
        width: 100%
        gap: 15px
        .filt_block
            display: flex
            // justify-content: space-between
            font-size: 13px
            // gap: 15px
            align-items: center
            display: flex
            flex-direction: row
            // grid-template-columns: 280px 200px
            width: 100%
            gap: 10px
            .price_filtr
                width: 50%
                gap: 10px
                &>input
                    font-size: 10px
                    width: 70px
                    height: 27px
            .sort
                width: 50%
                gap: 10px
                &>select
                    // width: 150px
                    height: 42px
        .search_block
            width: 100%
            display: flex
            justify-content: space-between
            .search
                gap: 28px
                width: 49%
                &>input
                    font-size: 13px
                    width: 100%
                    height: 42px
                    &>::placeholder
                        color: #ACACAC
            &>.btn_container
                width: 50%
                &>button
                // width: 190px
                height: 27px

@media (min-width: 992px)
    .filtr_container
        width: 80%
        gap: 20px
        .filt_block
            width: 100%
            font-size: 15px
            .price_filtr
                width: 50%
                &>input
                    border-radius: 7px
                    height: 30px
            .sort
                width: 50%
                &>select
                    border-radius: 7px
                    // width: 180px
                    height: 42px
        .search_block
            width: 100%
            display: flex
            justify-content: space-between
            .search
                width: 49%
                &>input
                    font-size: 13px
                    border-radius: 7px
                    height: 42px
            &>.btn_container
                width: 50%    
                &>button
                    border-radius: 7px
                    width: 100%
                    height: 42px

@media (min-width: 1200px)
    .filtr_container
        width: 70%
        gap: 35px
        .filt_block
            font-size: 20px
            .price_filtr
                width: 60%
                &>input
                    font-size: 15px
                    border-radius: 8px
                    height: 40px
            .sort
                width: 40%
                &>select
                    border-radius: 8px
                    width: 100%
                    height: 40px
                    font-size: 15px
        .search_block
            width: 100%
            .search
                width: 60%
                gap: 35px
                label
                    font-size: 30px
                &>input
                    font-size: 15px
                    border-radius: 8px
                    height: 40px
            &>.btn_container
                width: 40%
                &>button
                    font-size: 20px
                    border-radius: 8px
                    width: 100%
                    height: 40px

@media (min-width: 1440px)
    .filtr_container
        width: 70%
        .search_block
            width: 100%
            .search
                gap: 55px

