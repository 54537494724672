*
    margin: 0
    padding: 0
    box-sizing: border-box
    font-family: 'Montserrat', sans-serif

html
    scroll-behavior: smooth

body, html
    overflow-x: hidden

@media(max-width: 320px)
    .wrapper
        margin: 0px auto
        padding: 0 10px
        max-width: 320px

@media(max-width: 575px)
    .wrapper
        margin: 0px auto
        padding: 0 20px
        max-width: 575px

// @media(min-width: 576px)
//     .wrapper
//         margin: 0px auto
//         padding: 0 30px
//         max-width: 576px

@media(max-width: 768px)
    .wrapper
        max-width: 768px
        padding: 0 20px

@media(max-width: 992px)
    .wrapper
        max-width: 992px
        padding: 0 20px

@media(max-width: 1200px)
    .wrapper
        max-width: 1200px
        padding: 0 20px

@media(min-width: 1440px)
    .wrapper
        max-width: 1440px
        padding: 0 20px
