@media (max-width: 320px)
    .btn
        cursor: pointer
        width: 260px
        height: 60px
        font-size: 18px
        font-weight: 600
        color: #339933
        background: #F1FFF1
        border: 2px solid #339933
        border-radius: 21px
        transition: color 0.5s, background-color 0.5s
        &:hover
            background-color: #339933
            color: #F1FFF1

@media (min-width: 321px)
    .btn
        cursor: pointer
        width: 100%
        height: 70px
        font-size: 18px
        font-weight: 600
        color: #339933
        background: #F1FFF1
        border: 2px solid #339933
        border-radius: 21px
        transition: color 0.5s, background-color 0.5s
        &:hover
            background-color: #339933
            color: #F1FFF1
    
@media (min-width: 576px)    
    .btn
        cursor: pointer
        width: 280px
        height: 72px
        font-size: 18px
        font-weight: 600
        align-items: center
        justify-content: center
        color: #339933
        background: #F1FFF1
        border: 2px solid #339933
        border-radius: 21px
        transition: color 0.5s, background-color 0.5s
        &:hover
            background-color: #339933
            color: #F1FFF1
