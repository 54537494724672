@media(max-width: 767px)
    .btn_to_basket
        height: 50px
        border-radius: 7px
        background: #339933
        border: 2px solid #339933
        color: #FFFFFF
        font-weight: 700
        font-size: 16px
        cursor: pointer
        transition: color 0.5s, background-color 0.5s
        &:hover
            color: #339933
            background-color: #FFFFFF
            border-radius: 1px solid #339933

@media(min-width: 768px)
    .btn_to_basket
        height: 60px
        border-radius: 10px
        font-size: 18px
        background: #339933
        border: 2px solid #339933
        color: #FFFFFF
        font-weight: 700
        cursor: pointer
        transition: color 0.5s, background-color 0.5s
        &:hover
            color: #339933
            background-color: #FFFFFF
            border-radius: 1px solid #339933

@media(min-width: 992px)
    .btn_to_basket
        height: 70px
        border-radius: 13px
        font-size: 23px
        background: #339933
        border: 2px solid #339933
        color: #FFFFFF
        font-weight: 700
        cursor: pointer
        transition: color 0.5s, background-color 0.5s
        &:hover
            color: #339933
            background-color: #FFFFFF
            border-radius: 1px solid #339933

@media(min-width: 1200px)
    .btn_to_basket
        height: 80px
        font-size: 25px
        background: #339933
        border: 2px solid #339933
        color: #FFFFFF
        font-weight: 700
        cursor: pointer
        transition: color 0.5s, background-color 0.5s
        &:hover
            color: #339933
            background-color: #FFFFFF
            border-radius: 1px solid #339933

@media(min-width: 1440px)
    .btn_to_basket
        height: 90px
        font-size: 28px
        background: #339933
        border: 2px solid #339933
        color: #FFFFFF
        font-weight: 700
        cursor: pointer
        transition: color 0.5s, background-color 0.5s
        &:hover
            color: #339933
            background-color: #FFFFFF
            border-radius: 1px solid #339933
