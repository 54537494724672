@media(max-width: 575px)
    .slider
        display: flex
        align-items: center
        justify-content: center
        flex-wrap: wrap
        gap: 10px 40px

@media(min-width: 576px)
    .categories_container
        margin: 0 auto
        margin-bottom: 10px
    .slider
        display: flex
        flex-direction: column
    .block
        display: flex
        flex-direction: column
        gap: 40px

@media(min-width: 768px)
    .block
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 40px 30px
    .slider
        gap: 64px
        flex-direction: row

@media(min-width: 992px)
    .block
        grid-template-columns: repeat(3, 1fr)
        gap: 30px 10px
    .slider
        gap: 46px

@media (min-width: 1200px)
    .slider
        gap: 91px

@media(min-width: 1440px)
    .block
        grid-template-columns: repeat(4, 1fr)
    .slider
        gap: 30px
