@media(max-width: 320px)
    .promotions_container
        padding-top: 40px
        display: flex
        flex-direction: column
        gap: 20px
        .subheader
            font-size: 25px
            font-weight: 700
        .products_card
            display: flex
            flex-direction: column
            align-items: center
            gap: 10px

@media(max-width: 575px)
    .promotions_container
        padding-top: 40px
        display: flex
        flex-direction: column
        gap: 20px
        .subheader
            font-size: 25px
            font-weight: 700
        .products_card
            display: flex
            flex-direction: column
            align-items: center
            gap: 10px

@media(min-width: 576px)
    .promotions_container
        padding-top: 40px
        display: flex
        flex-direction: column
        gap: 20px
        .subheader
            font-size: 25px
            font-weight: 700
        .products_card
            display: flex
            flex-direction: column
            align-items: center
            gap: 10px

@media(min-width: 768px)
    .promotions_container
        .subheader
            font-size: 25px
        .products_card
            display: grid
            grid-template-columns: repeat(2, 1fr)

@media(min-width: 992px)
    .promotions_container
        .subheader
            font-size: 30px
        .products_card
            display: flex
            flex-direction: row

@media(min-width: 1200px)
    .promotions_container
        .subheader
            font-size: 40px
        .products_card
            gap: 30px

@media(min-width: 1440px)
    .promotions_container
        .products_card
            gap: 44px
