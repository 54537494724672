@media(max-width: 575px)
    .container
        min-height: calc(100vh - 140px)
        .wrapper
            padding: 30px 30px
            padding-top: 30px
            display: grid
            gap: 34px
            .subheader
                font-size: 20px
                color: #3c4139
                font-weight: 600
            .description
                display: flex
                flex-direction: column
                align-items: center
                .product_img
                    object-fit: contain
                    img
                        width: 100%
                        height: 100%
                .product_info
                    padding-top: 20px
                    display: flex
                    flex-direction: column
                    justify-content: center
                    gap: 40px
                    .price_and_btn_to_basket_container
                        display: grid
                        gap: 10px
                        .price_block
                            display: flex
                            align-items: center
                            justify-content: space-between
                            .clear_price
                                font-weight: 600
                                font-size: 32px
                            .discount_price
                                font-weight: 600
                                font-size: 32px
                            .price
                                font-size: 12px
                                font-weight: 500
                                text-decoration: line-through
                                color: #8B8B8B
                            .discount
                                font-size: 12px
                                font-weight: 600
                                color: #FF32A1
                    .description_block
                        display: flex
                        flex-direction: column
                        gap: 15px
                        max-height: 300px
                        .description_container
                            width: 100%
                            overflow: scroll
                            p
                                text-align: justify
                                font-size: 15px
                        .subheader_description
                            font-weight: 600
                            font-size: 22px
    
@media(min-width: 576px)
    .container
        min-height: calc(100vh - 140px)
        .wrapper
            padding: 30px 30px
            padding-top: 30px
            display: grid
            gap: 34px
            .subheader
                font-size: 20px
                color: #3c4139
                font-weight: 600
            .description
                display: flex
                flex-direction: column
                align-items: center
                .product_img
                    object-fit: contain
                    img
                        width: 100%
                        height: 100%
                .product_info
                    width: 70%
                    padding-top: 20px
                    display: flex
                    flex-direction: column
                    justify-content: center
                    gap: 40px
                    .price_and_btn_to_basket_container
                        display: grid
                        gap: 10px
                        .price_block
                            display: flex
                            align-items: center
                            justify-content: space-between
                            .clear_price
                                font-weight: 600
                                font-size: 42px
                            .discount_price
                                font-weight: 600
                                font-size: 42px
                            .price
                                font-size: 12px
                                font-weight: 500
                                text-decoration: line-through
                                color: #8B8B8B
                            .discount
                                font-size: 12px
                                font-weight: 600
                                color: #FF32A1
                    .description_block
                        display: flex
                        flex-direction: column
                        align-items: center
                        gap: 15px
                        max-height: 300px
                        .description_container
                            overflow: scroll
                            p
                                text-align: justify
                                font-size: 15px
                        .subheader_description
                            font-weight: 600
                            font-size: 22px

@media(min-width: 768px)
    .container
        .wrapper
            .description
                .product_info
                    width: 495px
                    .price_and_btn_to_basket_container
                        display: grid
                        width: 100%
                        margin: 0 auto
                        gap: 20px
                        .price_block
                            padding: 0 20px

@media(min-width: 992px)
    .container
        min-height: calc(100vh - 170px)
        .wrapper
            padding-top: 30px
            display: grid
            gap: 34px
            .subheader
                font-size: 30px
            .description
                height: 100%
                display: grid
                grid-template-columns: 55% 1fr
                grid-auto-flow: row
                gap: 30px
                align-items: center
                .product_info
                    padding-top: 0px
                    height: 100%
                    justify-content: flex-start
                    .price_and_btn_to_basket_container
                        display: grid
                        width: 100%
                        gap: 10px
                        .price_block
                            padding: 0px
                            display: flex
                            .clear_price
                                font-size: 64px
                            .discount_price
                                font-size: 64px
                            .price
                                font-size: 24px
                            .discount
                                font-size: 24px
                    .description_block
                        .description_container
                            overflow: scroll
                            &>p
                                font-size: 17px
                        .subheader_description
                            font-size: 24px

@media(min-width: 1200px)
    .container
        min-height: calc(100vh - 170px)
        .wrapper
            padding-top: 30px
            gap: 40px
            .subheader
                font-size: 35px
            .description
                gap: 40px
                .product_info
                    .price_and_btn_to_basket_container
                        gap: 20px
                        .price_block
                            .clear_price
                                font-size: 66px
                            .discount_price
                                font-size: 66px
                            .price
                                font-size: 26px
                            .discount
                                font-size: 26px
                    .description_block
                        max-height: 500px
                        .description_container
                            overflow: scroll
                            &>p
                                font-size: 18px
                        .subheader_description
                            font-size: 26px

@media(min-width: 1440px)
    .container
        .wrapper
            padding: 30px 30px
            padding-top: 60px
            .subheader
                font-size: 36px
            .description
                height: 100%
                display: grid
                grid-template-columns: 60% 1fr
                gap: 50px
                .product_info
                    .price_and_btn_to_basket_container
                        gap: 40px
                        .price_block
                            .clear_price
                                font-size: 70px
                            .discount_price
                                font-size: 70px
                            .price
                                font-size: 28px
                            .discount
                                font-size: 28px
                    .description_block
                        max-height: 550px
                        .description_container
                            overflow: scroll
                            &>p
                                font-size: 20px
                        .subheader_description
                            font-size: 28px
